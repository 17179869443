var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.$can('update', _vm.modulo))?[(_vm.tipoEstado == 'normal')?_c('b-button',{staticClass:"btn-sm",attrs:{"variant":_vm.data.item.estado == 'Activo'
        ? 'flat-success'
        : 'flat-danger',"title":_vm.data.item.estado == 'Activo'
        ? 'Desactivar'
        : 'Activar'},on:{"click":function($event){_vm.$can('update', _vm.modulo)
        ? _vm.$emit('processUpdateEstado', _vm.data.item)
        : ''}}},[_vm._v(" "+_vm._s(_vm.data.item.estado)+" ")]):_vm._e()]:[(_vm.tipoEstado == 'normal')?_c('b-badge',{attrs:{"variant":_vm.data.item.estado == 'Activo'
        ? 'light-success'
        : 'light-danger'}},[_vm._v(" "+_vm._s(_vm.data.item.estado)+" ")]):_vm._e()],(_vm.tipoEstado == 'producto')?_c('b-badge',{attrs:{"variant":_vm.data.item.estado == 'Disponible'
      ? 'light-success'
      : 'light-danger'}},[_vm._v(" "+_vm._s(_vm.data.item.estado)+" ")]):_vm._e()],2)}
var staticRenderFns = []

export { render, staticRenderFns }