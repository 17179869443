<template>
  <div>
    <template v-if="$can('update', modulo)">
      <b-button
        v-if="tipoEstado == 'normal'"
        :variant="data.item.estado == 'Activo'
          ? 'flat-success'
          : 'flat-danger'"
        :title="data.item.estado == 'Activo'
          ? 'Desactivar'
          : 'Activar'"
        @click="$can('update', modulo)
          ? $emit('processUpdateEstado', data.item)
          : ''"
        class="btn-sm"
      >
        {{ data.item.estado }}
      </b-button>
    </template>
    <template v-else>
      <b-badge
        v-if="tipoEstado == 'normal'"
        :variant="data.item.estado == 'Activo'
          ? 'light-success'
          : 'light-danger'"
      >
        {{ data.item.estado }}
      </b-badge>
    </template>

    <b-badge
      v-if="tipoEstado == 'producto'"
      :variant="data.item.estado == 'Disponible'
        ? 'light-success'
        : 'light-danger'"
    >
      {{ data.item.estado }}
    </b-badge>
  </div>
</template>

<script>
import { BBadge, BButton } from 'bootstrap-vue'

export default {
  components: {
    BBadge,
    BButton,
  },
  props: {
    data: {
      type: Object,
      required: true,
    },
    modulo: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      tipoEstado: false,
    }
  },
  mounted() {
    if (this.data.item.estado === 'Activo'
      || this.data.item.estado === 'Inactivo') {
      this.tipoEstado = 'normal'
    } else {
      this.tipoEstado = 'producto'
    }
  },
}
</script>
